/**
 * CHANGES:
 * 
 * Add filter to search for columns across all data sources (under the header)
 * Show active data source and collapse all otehrs when you click on a mission control edge
 * Hide delete buttons until hover
 * Add placeholder drop
 * Change "fields" header to "{bo.name} Fields"
 * Add "One row per _____" under the Fields header
 * Allow dragging data sources from data library
 * Click to edit field name
 * 
 * Add "special plb fields" to the data source (use SourceRecordType.column_preferences for special static fields)
 *  - Loaded At
 *  - Source Name
 *  - Add your own static field
 * 
 */


import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import 'reactflow/dist/style.css';
import { useNavigate } from "react-router-dom";
import { Updater, useImmer } from 'use-immer';
import {  usePipelineNodes, usePipelineNodeRelationships, useIsInDraftMode, } from '@stores/data.store';
import styled from 'styled-components';
import { Badge, Form, Modal, Offcanvas, } from "react-bootstrap";
import { DndProvider, DragSourceMonitor, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useDebounce, useThrottledCallback } from "use-debounce";
import Danger from "@components/statusIndicators/Danger.component";
import { Pane, PaneContent } from "@pages/PageStructure.component";
import { NodeFieldJoinPath, PipelineNode, PipelineNodeField, PipelineNodeMapOption, PipelineNodeRelationship } from "@models/pipelineNode";
import PipelineNodeColumnDrawer from "@components/pipelineNodes/PipelineNodeColumnDrawer.component";
import PipelineNodeFieldTranslation from "@components/pipelineNodes/PipelineNodeFieldTranslation.component";
import { shortid } from "@services/id.service";
import Warning from "@components/statusIndicators/Warning.component";
import PipelineNodeFieldEditor from "./PipelineNodeFieldEditor.component";
import PipelineNodeFieldMappingDataSourceList from "./PipelineNodeFieldMappingDataSourceList.component";
import PipelineNodeSelector from "../PipelineNodeSelector.component";
import { isValidUpstreamNode } from "@services/modeling.service";
import OverflowTooltip from "@components/tooltip/OverflowTooltip.component";
import PipelineNodeWhitelistConfiguration from "../configuration/PipelineNodeWhitelistConfiguration.component";


const HeaderContainer = styled.div`
display: flex;
h4 {
    font-family: "Poppins";
    color: black;
    font-size: 24px;
    font-weight: 600;
    flex: 1;
}

button {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    text-align: center;
    padding: 0px;
    margin: 0px;
    border: none;
    background-color: #E9E9E9;
    color: black;

    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

    &:hover {
        background-color: #00A1E0;
        color: white;
    }
}
`

const SubHeaderContainer
 = styled.div`
 height: 50px;
`

const MergeInfo = styled.div`

font-size: 13px;
border-left: solid 4px var(--ct-border-color);
padding-left: 1rem;
margin-top: 1rem;
margin-bottom: 1rem;
`


const FieldListSubHeader = styled.h5`
margin-top: 2rem;
color: #aaa;
font-weight: 400;

button {
    font-size: 13px;
    background: none;
    padding: none;
    border: none;
    color: var(--ct-body-color);


    &:hover {
        color: black;
        cursor: pointer;
    }

    &.highlight {
        color: var(--pliable-yellow);
    }
}
`

const FieldTitle = styled.div`
display: flex;
align-items: center;
margin-bottom: 0px;


&:hover {
    cursor: pointer;
    
}

img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: solid 1px var(--ct-border-color);
    margin-right: 1rem;
}

h3 {
    input {
        width: 75%;
    }
    flex: 1;
    color: black;
    margin-bottom: 0px;
}

h4 {
    flex: 1;
    color: black;
    margin-bottom: 0px;
}

button {
    background: none;
    padding: none;
    border: none;
    color: var(--ct-body-color);
    font-size: 18px;


    &:hover {
        color: black;
        cursor: pointer;
    }

    &.highlight {
        color: var(--pliable-yellow);
    }

    &:disabled {
        color: #ccc;
        cursor: default;
    }
}
`


const DndContainer = styled.div`

`

const DraggableFieldStyles = styled.div`
padding: 4px 8px;

margin-bottom: .5rem;
display: flex;
flex-direction: row;
line-height: 24px;
align-items: center;

.column-label {
    flex: 1;
    font-size: 14px;
}

.icons {
    font-size: 18px;
}

&:hover {
    background-color: #eee;
    cursor: move;
}


&.disabled {
    &:hover {
        background-color: white !important;
    }
}
user-select: none;
`



const Loader = styled.div`
position: fixed;
top: 50%;
left: 50%;
z-index: 2;
width: 100px;
height: 85px;
background: rgba(0, 0, 0, 0.5);
color: white;
border-radius: 10px;
line-height: 100px;
text-align: center;
`


const SelectionCard = styled.div`
    box-shadow: 0px 2px 5px 0px #0000004D;
    width: 200px;
    height: 50px;
    padding: 12px;
    border-radius: 5px;
    background: #0F0E31;
    color: #ffffff;
    text-align: center;

`


const FieldContainer = styled.div`
padding: 16px 20px;
margin-bottom: 1rem;

&.selectable {
    &:hover {
        cursor: pointer;
        outline: solid 1px var(--pliable-blue);
    }
}

&.active {
    outline: solid 2px var(--pliable-blue);

    &:hover {
        outline: solid 2px var(--pliable-blue);
    }
}

`

const ScrollCatcher = styled.div`
position: fixed;
right: 0px;
width: 100%;
height: 50px;
background: none;
z-index: 1000;

&.top {
    top: 100px;
}

&.bottom {
    bottom: 0px;
}
`



interface SelectionCardProps {
    onCancel: () => void;
}



// interface BusinessObjectMappingsFlowProps {
//     businessObjectFields: BusinessObjectField[];
//     setFields: (fields: BusinessObjectField[]) => void;
//     recordTypeMappings: StandardizationPipeline[];
//     setRecordTypeMappings: (mappings: StandardizationPipeline[]) => void;
// }

const DropTargetContainer = styled.div`
&.is-over .drop-target {
    background-color: #eee;
}

&.can-drop .drop-target {
    outline: dashed 3px var(--pliable-yellow);
}
`

const DataPaneHeader = styled.div`
background: #2E2E2E;
color: white;
height: 30px;
line-height: 30px;
padding: 0px 1rem;
display: flex;
flex-direction: row;
cursor: pointer;

h2 {
    margin: 0px;
    padding: 0px;
    color: white !important;
    line-height: 30px;
    font-weight: 200;
    flex: 1;
}

button {
    border: none;
    background: none;
    padding: 0px;
    margin: 0px;
    color: white;
    font-size: 24px;
    font-weight: bold;
    color: #eee;

    &:hover {
        color: white;
        cursor: pointer;
    }
}
`

interface DropTargetProps {
    field: PipelineNodeField;
    children: ReactNode;
}

const DropTarget = (props: DropTargetProps) => {
    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
          accept: 'FIELD',
          drop: () => ({
            fieldId: props.field.id,
          }),
          collect: (monitor: any) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
          }),
        }),
        [props.field.id],
    );

    const classes = [];

    if (canDrop) {
        classes.push('can-drop');
    }

    if (isOver) {
        classes.push('is-over');
    }
    return <DropTargetContainer ref={drop} className={classes.join(' ')}>
        {props.children}
    </DropTargetContainer>
}

interface DraggableFieldProps {
    column: PipelineNodeField;
    sourceNodeId: string;
    showCheckMark?: boolean;
    onDrop: (sourceNodeId: string, sourceField: PipelineNodeField, droppedFieldId: string) => void;
    setIsDragging: (isDragging: boolean) => void;
    disableActions?: boolean;
    onShowColumnStats?: () => any;

}

const DraggableField = (props: DraggableFieldProps) => {
    const [{ isDragging, opacity }, drag] = useDrag(() => ({
        type: 'FIELD',
        item: {
            
        },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult() as any;
            if (item && dropResult) {
                let alertMessage = ''
                const isDropAllowed = true;
            }

            if (dropResult) {
                props.onDrop(props.sourceNodeId, props.column, dropResult.fieldId);

            }
        },
        collect: (monitor: DragSourceMonitor) => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
            isDragging: monitor.isDragging(),
        }),
    }));

    useEffect(() => {
        props.setIsDragging(isDragging);
    }, [isDragging])

    return <DraggableFieldStyles ref={drag} className={`shadow-box ${props.disableActions ? 'disabled' : ''}`}>
        <div className="column-label">{props.column.label}</div>
        <div className="icons">

            {props.showCheckMark && <span>
                <i className="mdi mdi-check-bold text-success"></i>
            </span>}
            <button className="icon-button" onClick={props.onShowColumnStats}>
                <i className="mdi mdi-eye"></i>
            </button>
            <span>
                <i className="mdi mdi-drag"></i>
            </span>

        </div>
    </DraggableFieldStyles>


}

interface Transformer {
    label: string;
    description: string;
    value: string;
    takesArguments?: boolean;
    snowflakeOnly?: boolean;
}


interface FieldListItemProps {
    field: PipelineNodeField;
    onDeleteField: (field: PipelineNodeField) => void;
    toggleFieldCompositeKey: (fieldId: string) => void;
    onSelectField: (fieldId: string) => void;
    isActive?: boolean;
    enableMerge?: boolean;
    disabled?: boolean;
}

const FieldListItem = ({onDeleteField, field, toggleFieldCompositeKey, onSelectField, isActive, enableMerge, disabled}: FieldListItemProps) => {
    if (field.type == 'FOREIGN_KEY' || field.type == 'DENORMALIZED' || field.type == 'IDENTITY') {
        return <FieldContainer className={`shadow-box selectable ${isActive ? 'active' : ''}`} onClick={() => onSelectField(field.id)}>
            <FieldTitle><h3>{field.label}</h3></FieldTitle>
        </FieldContainer>
        
        
    }
    return <DropTarget
        field={field}
    >
        <FieldContainer className={`drop-target shadow-box selectable ${isActive ? 'active' : ''}`} onClick={() => onSelectField(field.id)}>
            <OverflowTooltip
             className="label overflow-ellipsis"
             text={field.label}
             as="h3"
             />
            <FieldTitle>
                    {!field.map_options.length && !field.advanced_mode && (
                        <div className="text-warning">
                            <i className="mdi mdi-alert"></i>
                        </div>
                    )}
                    {!field.advanced_mode && field.map_options.length >= 1 && (
                        <Badge pill bg="info">{field.map_options.length}</Badge>
                    )}
                    {field.advanced_mode && (
                        <Badge bg="dark"><i className="mdi mdi-code-braces"></i></Badge>
                        
                    )}
                    {!disabled && (
                        <button  className="ms-1" onClick={(e) => { 
                            e.stopPropagation();
                            onDeleteField(field);
                       }}>
                            <i className="mdi mdi-delete"></i>
                        </button>
                    )}
                    
                    {enableMerge && (
                        <button disabled={field.advanced_mode || disabled} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            toggleFieldCompositeKey(field!.id as string);
                        }} className={'' + (field!.part_of_composite_key ? 'highlight' : '')}>
                            <i className="mdi mdi-key"></i>
                        </button>
                    )}
                    
            </FieldTitle>
            <div className="overflow-ellipsis">{field.description}</div>
        </FieldContainer>
    </DropTarget>
}




interface AddFieldButtonProps {
    onAddNewField: () => void;
    addTo: 'TOP' | 'BOTTOM';
}
const AddFieldButton = (props: AddFieldButtonProps) => {
    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
          accept: 'FIELD',
          drop: () => ({
            fieldId: 'NEW_' + props.addTo,
          }),
          collect: (monitor: any) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
          }),
        }),
        [],
    );

    const classes = ['mb-2'];

    if (canDrop) {
        classes.push('can-drop');
    }

    if (isOver) {
        classes.push('is-over');
    }
    return <DropTargetContainer ref={drop} className={classes.join(' ')}>
        <button className="btn btn-lg w-100 btn-ghost drop-target" onClick={() => props.onAddNewField()}>Add New Column</button>
    </DropTargetContainer>
}

interface RelatedNode {
    node: PipelineNode;
    relationship: PipelineNodeRelationship;
}


interface Props {
    node: PipelineNode;
    onChange: Updater<PipelineNode>
    enableMerge?: boolean;
    requireMerge?: boolean;
    limitToSingleSource?: boolean;
    includeRelationships?: boolean;
    mergeTextMode?: 'MERGE' | 'GROUP_BY' | 'IDENTIFY';
    useGroupByInsteadOfMerge?: boolean;
    limitToSingleFieldMap?: boolean;
}

const PipelineNodeFieldsMapping = (props: Props) => {
    const pipelineNodes = usePipelineNodes();
    const relationships = usePipelineNodeRelationships(props.node.id as string);
    
    const navigate = useNavigate();

    const [showAddSourceModal, setShowAddSourceModal] = useState(false);
    const [showAddRelationshipModal, setShowAddRelationshipModal] = useState(false);

    const [newColConnectingHandleId, setNewColConnectingHandleId] = useState('');

    
    const [relatedNodes, setRelatedNodes] = useState<RelatedNode[]>([]);

    useEffect(() => {
        if (!pipelineNodes.data || !relationships.data) {
            return;
        }

        const parsed: RelatedNode[] = [];
        relationships.data.forEach(r => {
            let relatedNodeId = '';
            if (r.child_node_id === props.node.id as string) {
                relatedNodeId = r.parent_node_id;
            } else {
                relatedNodeId = r.child_node_id;
            }

            const relatedNode = pipelineNodes.data.find(n => n.id === relatedNodeId);
            if (relatedNode) {
                parsed.push({
                    node: relatedNode,
                    relationship: r,
                });
            }
        });

        setRelatedNodes(parsed);
    }, [pipelineNodes.dataUpdatedAt, relationships.dataUpdatedAt, props.node.id]);


    
    const setFields = useCallback((newFields: PipelineNodeField[]) => {
        props.onChange(draft => {
            draft.fields = newFields;
        })
    }, [props.onChange]);

    const addNewFieldAtBottom = useCallback(() => {
        const newId = shortid();
        props.onChange(draft => {
            draft.fields.push({
                id: newId,
                name: '',
                description: '',
                part_of_composite_key: false,
                label: 'New Field', 
                type: 'STRING',
                map_options: [],
                taxonomic_id: '',
                cell_actions: [],
            });
        });
        setActiveFieldId(newId);
    }, [props.onChange]);

    const addNewFieldAtTop = useCallback(() => {
        const newId = shortid();
        props.onChange(draft => {
            draft.fields.unshift({
                id: newId,
                name: '',
                description: '',
                part_of_composite_key: false,
                label: 'New Field', 
                type: 'STRING',
                map_options: [],
                taxonomic_id: '',
                cell_actions: [],
            })
        })
       
        setActiveFieldId(newId);
    }, [props.onChange]);

    const sortFieldsAlphabetically = useCallback(() => {
        props.onChange(draft => {
            draft.fields = draft.fields.sort(function(a, b) {
                var x = a['name']; var y = b['name'];
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            });
        });
    }, [props.onChange]);

    const [setDataFirstTime, setSetDataFirstTime] = useState(false);

    const onDeleteField = useCallback((field: PipelineNodeField) => {
        props.onChange(draft => {
            const idx = draft.fields.findIndex(d => d.id === field.id);
            if (idx >= 0) {
                draft.fields.splice(idx, 1);
            }
        })
    }, [props.onChange]);

    const [selectedFieldIds, setSelectedFieldIds] = useImmer<string[]>([]);

    const toggleSelectedField = useCallback((fieldId: string) => {
        setSelectedFieldIds(draft => {
            const index = draft.indexOf(fieldId);
            if (index >= 0) {
                draft.splice(index, 1);
            } else {
                draft.push(fieldId);
            }
        });
    }, []);    

    const [searchInput, setSearchInput] = useState('');
    const [searchTerm] = useDebounce(searchInput, 250);

    const [boSearchInput, setBoSearchInput] = useState('');
    const [boSearchTerm] = useDebounce(boSearchInput, 250);

    const usedFields = useMemo(() => {
        const used: string[] = [];
        props.node.fields.forEach(f => {
            f.map_options?.forEach(mo => {
                if (mo.source_node_id) {
                    used.push(mo.source_node_id + ':' + mo.attribute_key);
                } else if (mo.sub_options) {
                    mo.sub_options.forEach(so => {
                        used.push(so.source_node_id + ':' + so.attribute_key);
                    });
                }
            });
        });
        return used;
    }, [props.node.fields])


    const filteredColumns = useMemo(() => {
        if (!pipelineNodes.data) {
            return []
        }
        const term = searchTerm.toLowerCase();


        return props.node.upstream_node_ids.map(upstreamNodeId => {
            const upstreamNode = pipelineNodes.data.find(s => s.id === upstreamNodeId);
            const theseFilteredColumns = upstreamNode?.fields?.filter(c => c.label.toLowerCase().indexOf(term) >= 0 || c.label.toLowerCase().indexOf(term) >= 0).sort((a, b) => {
                if (a.label > b.label) {
                    return 1;
                }
                return -1;
            });


            // Now break them into used/unused
            if (!theseFilteredColumns) {
                return {
                    'used': [],
                    'unused': [],
                }
            } 
            return {
                'used': theseFilteredColumns.filter(c => usedFields.includes(upstreamNodeId + ':' + c.name)),
                'unused': theseFilteredColumns.filter(c => !usedFields.includes(upstreamNodeId + ':' + c.name)),
            }

        });
    }, [props.node.upstream_node_ids, searchTerm, pipelineNodes.dataUpdatedAt, usedFields]);


    const mergeText = useMemo(() => {
        const compositeFieldNames = props.node.fields.filter(f => f.part_of_composite_key).map(f => f.label);
        let joiner: string = ' and ';
        if (props.node.combine_logic_gate == 'OR') {
            joiner = ' or ';
        }
        if (compositeFieldNames.length) {
            let fieldText = '';
            if (compositeFieldNames.length === 1) {
                fieldText = compositeFieldNames[0];
            } else if (compositeFieldNames.length === 2) {
                fieldText = compositeFieldNames.join(joiner);
            } else {
                let last = compositeFieldNames.pop();
                fieldText = compositeFieldNames.join(', ') + ', ' + joiner + last;
            }
            return fieldText;
        }
        return '';

        


    }, [props.node.fields, props.node.combine_logic_gate]);

    const onChangeFieldName = useCallback((fieldId: string, newName: string) => {
        props.onChange(draft => {
            const theField = draft.fields.find(f => f.id === fieldId);
            if (theField) {
                theField.label = newName;
                theField.name = newName;
            }
        })
        
    }, [props.onChange]);

    const onChangeFieldType = useCallback((fieldId: string, newType: string) => {

        props.onChange(draft => {
            const theField = draft.fields.find(f => f.id === fieldId);
            if (theField) {
                theField.type = newType;
            }
        })
        
    }, [props.onChange]);

    const onChangeFieldDescription = useCallback((fieldId: string, newDescription: string) => {

        props.onChange(draft => {
            const theField = draft.fields.find(f => f.id === fieldId);
            if (theField) {
                theField.description = newDescription;
            }
        });
    }, [props.onChange]);

    const onChangeFieldTransformer = useCallback((fieldId: string, newTransformer: string) => {
        props.onChange(draft => {
            const theField = draft.fields.find(f => f.id === fieldId);
            if (theField) {
                theField.transformer = newTransformer;
            }
        });
    }, [props.onChange]);

    const onToggleFieldAdvancedMode = useCallback((fieldId: string) => {
        props.onChange(draft => {
            const theField = draft.fields.find(f => f.id === fieldId);
            if (theField) {
                theField.advanced_mode = !theField.advanced_mode;
                if (!theField.advanced_mode) {
                    theField.custom_sql = ''
                }
            }
        });
    }, [props.onChange]);

    const onChangeFieldCustomSQL = useCallback((fieldId: string, customSql: string) => {
        props.onChange(draft => {
            const theField = draft.fields.find(f => f.id === fieldId);
            if (theField) {
                theField.custom_sql = customSql;
            }
        });
    }, [props.onChange])

    const toggleFieldCompositeKey = useCallback((fieldId: string) => {
        props.onChange(draft => {
            const theField = draft.fields.find(f => f.id === fieldId);
            if (theField) {
                theField.part_of_composite_key = !theField.part_of_composite_key;
            }
        })
        
    }, [props.onChange]);

    

    const onDrop = useCallback((sourceNodeId: string, sourceColumn: PipelineNodeField, targetFieldId: string, joinPath?: NodeFieldJoinPath[]) => {
        let fieldId: string;
        
        if (targetFieldId.startsWith('NEW')) {
            fieldId = shortid();
        } else {
            fieldId = targetFieldId;
        }

        props.onChange(draft => {
            const fieldType = (['DENORMALIZED', 'FOREIGN_KEY', 'IDENTITY'].includes(sourceColumn.type)) ? 'STRING' : sourceColumn.type;
            if (targetFieldId == 'NEW_TOP') {
                draft.fields.unshift({
                    id: fieldId,
                    label: sourceColumn.label,
                    name: sourceColumn.label,
                    part_of_composite_key: false,
                    type: fieldType,
                    taxonomic_id: '',
                    description: '',
                    map_options: [
                        {
                            id: shortid(),
                            source_node_id: sourceNodeId,
                            attribute_key: sourceColumn.name,
                            attribute_id: sourceColumn.id,
                            combination_rule: 'PICK_ONE',
                            join_path: joinPath,

                        }
                    ],
                    cell_actions: []
                });
            } else if (targetFieldId == 'NEW_BOTTOM') {
                draft.fields.push({
                    id: fieldId,
                    label: sourceColumn.label,
                    name: sourceColumn.label,
                    part_of_composite_key: false,
                    type: fieldType,
                    taxonomic_id: '',
                    description: '',
                    map_options: [
                        {
                            id: shortid(),
                            source_node_id: sourceNodeId,
                            attribute_key: sourceColumn.name,
                            attribute_id: sourceColumn.id,
                            combination_rule: 'PICK_ONE',
                            join_path: joinPath,
                        }
                    ],
                    cell_actions: []
                });
            } else {
                const theField = draft.fields.find(f => f.id === targetFieldId);
                if (!theField) {
                    throw new Error('field not found');
                }


                if (!theField.map_options) {
                    theField.map_options = [];
                }

                if (props.limitToSingleFieldMap && theField.map_options.length >= 1) {
                    theField.map_options = [];
                }

                theField.map_options.push({
                    id: shortid(),
                    source_node_id: sourceNodeId,
                    attribute_key: sourceColumn.name,
                    attribute_id: sourceColumn.id,
                    combination_rule: 'PICK_ONE', 
                    join_path: joinPath,
                });
            }
        });
        
        // setActiveFieldId(fieldId);
    }, [props.onChange]);
    
    const [visibleSources, setVisibleSources] = useImmer<string[]>([]);
    
    const toggleSourceVisibility = useCallback((srtId: string) => {
        setVisibleSources(draft => {
            if (draft.includes(srtId)) {
                const index = draft.indexOf(srtId);
                draft.splice(index, 1);
            } else {
                draft.push(srtId);
            }
        });
    }, []);

    const scrollingContainer = useRef<HTMLDivElement>(null);

    const scrollUp = useThrottledCallback(() => {
        if (!scrollingContainer.current) {
            return;
        }

        const currentY = scrollingContainer.current.scrollTop;
        if (currentY > 0) {
            scrollingContainer.current.scrollTo({
                behavior: 'smooth',
                top: currentY - 500,
            });
        }
    }, 1000);

    const scrollDown = useThrottledCallback(() => {
        if (!scrollingContainer.current) {
            return;
        }

        const currentY = scrollingContainer.current.scrollTop;
        if (currentY < scrollingContainer.current.scrollHeight) {
            scrollingContainer.current.scrollTo({
                behavior: 'smooth',
                top: currentY + 500,
            });
        }
    }, 1000);

    const [showMergeTutorial, setShowMergeTutorial] = useState(false);

    const [currentlyDragging, setCurrentlyDragging] = useState('');

    const mergingFields = useMemo(() => {
        return props.node.fields.filter(f => f.part_of_composite_key);
    }, [props.node.fields]);
    const nonMergingFields = useMemo(() => {
        const lower = boSearchTerm.toLowerCase();
        return props.node.fields.filter(f => !f.part_of_composite_key && f.label.toLowerCase().indexOf(lower) >= 0);
    }, [props.node.fields, boSearchTerm]);

    const addAllFieldsFromSource = useCallback((upstreamNodeId: string) => {
        if (!pipelineNodes.data) {
            return;
        }

        const theSrt = pipelineNodes.data.find(s => s.id === upstreamNodeId);
        if (!theSrt) {
            return;
        }

        const idx = props.node.upstream_node_ids.indexOf(upstreamNodeId);

        const unusedFields = filteredColumns[idx].unused;
        props.onChange(draft => {

            unusedFields.forEach(f => {
                // Find a BO field with the same name and add it if so, otherwise create
                const mapOption = {
                    id: shortid(),
                    source_node_id: upstreamNodeId,
                    attribute_key: f.name,
                    attribute_id: f.id,
                    combination_rule: 'PICK_ONE', 
                };
                const fieldWithSameName = draft.fields.find(field => field.label.toLowerCase() == f.label.toLowerCase());
                if (fieldWithSameName) {
                    if (!fieldWithSameName.map_options) {
                        fieldWithSameName.map_options = [];
                    }

                    fieldWithSameName.map_options.push(mapOption);

                } else {
                    draft.fields.push({
                        id: shortid(),
                        name: f.label,
                        label: f.label,
                        part_of_composite_key: false,
                        type: f.type,
                        map_options: [mapOption],
                        taxonomic_id: '',
                        description: '',
                        cell_actions: [],
                    })
                }
            })
        });


    }, [props.onChange, pipelineNodes.dataUpdatedAt, props.node.upstream_node_ids]);

    const [building, setBuilding] = useState(false);
    
    const [activeColumnPipelineNodeId, setActiveColumnPipelineNodeId] = useState('');
    const [activeColumn, setActiveColumn] = useState<PipelineNodeField|undefined>(undefined);
    const onShowColumnStats = useCallback((pnId: string, column: PipelineNodeField) => {
        setActiveColumnPipelineNodeId(pnId);
        setActiveColumn(column);
    }, []);


    const [activeFieldForTranslations, setActiveFieldForTranslations] = useState<PipelineNodeField|undefined>(undefined);
    const onEditFieldTranslations = useCallback((fieldId: string) => {
        const theField = props.node.fields.find(f => f.id === fieldId);
        if (theField) {
            setActiveFieldForTranslations(theField);
        }
    }, [props.node.fields]);

    const [activeFieldId, setActiveFieldId] = useState('');
    const activeField = useMemo(() => {
        if (activeFieldId) {
            const af = props.node.fields.find(f => f.id === activeFieldId);
            return af;
        }
        return undefined;
    }, [activeFieldId, props.node.fields]);

    const onUpdateActiveFieldAttribute = useCallback((attr: keyof PipelineNodeField, value: any) => {
        props.onChange(draft => {
            const idx = draft.fields.findIndex(f => f.id === activeFieldId);
            if (idx >= 0) {
                // @ts-ignore
                draft.fields[idx][attr] = value;

            }
        });
        
    }, [props.onChange, activeFieldId])

    const fieldsForAutocomplete = useMemo(() => {
        if (!activeField) {
            return [];
        }

        // Can't use other formulas in a formula, just to be safe for order of operations
        return props.node.fields.filter(f => f.id !== activeField.id && !f.advanced_mode).map(f => f.name)
    }, [props.node.fields, activeField]);

    const onDropHandler = (srcNodeId: string, sourceField: PipelineNodeField) => {
        return (fieldId: string) => {
            onDrop(srcNodeId, sourceField, fieldId);
        }
    }


    const [newSourceSelection, setNewSourceSelection] = useState('');

    const newSourceOptionFilter = useCallback((pn: PipelineNode) => {
        return isValidUpstreamNode(props.node, pn);
    }, [props.node.upstream_node_ids, props.node])

    const onConnectSource = useCallback(async () => {
        props.onChange(draft => {
            draft.upstream_node_ids.push(newSourceSelection);
        })
        setShowAddSourceModal(false);
        
    }, [props.onChange, newSourceSelection]);

    const onUpsertColumns = useCallback((sourceNodeId: string, columns: PipelineNodeField[], joinPath?: NodeFieldJoinPath[]) => {
        props.onChange(draft => {
            columns.forEach(c => {
                const found = draft.fields.find(f => f.name.toLowerCase() == c.name.toLowerCase());
                const mapOption = {
                    id: shortid(),
                    source_node_id: sourceNodeId,
                    join_path: joinPath,
                    attribute_key: c.name,
                    attribute_id: c.id,
                };
                if (found) {
                    found.map_options.push(mapOption)
                } else {
                    draft.fields.push({
                        id: shortid(),
                        name: c.label,
                        label: c.label,
                        part_of_composite_key: false,
                        type: c.type || 'STRING',
                        map_options: [mapOption],
                        taxonomic_id: '',
                        description: '',
                        cell_actions: [],
                    })
                }
            })
        })
    }, [props.onChange]);

    const updateUpstreamNodeIds = useCallback((newUpstreamIds: string[]) => {
        // Updates the upstreamNodeIds and removes any mapped fields for no-longer-connected upstream nodes
        props.onChange(draft => {
            draft.upstream_node_ids = newUpstreamIds;

            draft.fields.forEach(f => {
                if (f.map_options) {
                    const newMapOptions: PipelineNodeMapOption[] = [];
                    f.map_options.forEach(mo => {
                        if (mo.sub_options) {
                            const newSubOptions: PipelineNodeMapOption[] = [];
                            mo.sub_options.forEach(so => {
                                if (newUpstreamIds.includes(so.source_node_id as string)) {
                                    newSubOptions.push(so);
                                }
                            });
                            mo.sub_options = newSubOptions;
                        }
                        if (newUpstreamIds.includes(mo.source_node_id as string)) {
                            newMapOptions.push(mo);
                        }
                    });
                    f.map_options = newMapOptions;
                }
            })
        })
    }, [props.onChange]);

    const inDraftMode = useIsInDraftMode();

    const [showColumnEditor, setShowColumnEditor] = useState(false);

    const onSaveEditingField = useCallback((editingField: PipelineNodeField) => {
        props.onChange(draft => {
            const idx = draft.fields.findIndex(f => f.id === editingField.id);
            if (idx >= 0) {
                draft.fields[idx] = editingField;
            }
        });
        setActiveFieldId('');
        setShowColumnEditor(false);
    }, [activeFieldId, props.onChange]);

    useEffect(() => {
        if (!!activeFieldId) {
            setShowColumnEditor(true);
        }
    }, [activeFieldId]);

    return <Pane>
        <Offcanvas
            show={showColumnEditor}
            onHide={() => {
                setShowColumnEditor(false);
                setActiveFieldId('');
            }}
            placement="end"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    Configure Column
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Pane>
                    <PaneContent>

                        
                        
                        {activeField && <>
                            
                            <PipelineNodeFieldEditor
                                pipelineNodeId={props.node.id as string}
                                upstreamNodeIds={props.node.upstream_node_ids}
                                key={activeField.id} 
                                field={activeField} 
                                allFields={props.node.fields}
                                onChange={onUpdateActiveFieldAttribute}
                                isMerging={!!mergeText}
                                combineBehavior={props.node.combine_behavior}
                                onEditTranslations={onEditFieldTranslations}
                                otherFieldsForAutocomplete={fieldsForAutocomplete}
                                disableMultiple={props.node.node_type == 'DATAMART'}
                                displayJoinPath={true}
                                onSave={onSaveEditingField}
                                onCancel={() => {
                                    setActiveFieldId('');
                                    setShowColumnEditor(false);
                                }}
                            />
                        </>}
                    </PaneContent>
                </Pane>
            </Offcanvas.Body>
        </Offcanvas>
        <Modal size="lg" show={!!activeFieldForTranslations} onHide={() => setActiveFieldForTranslations(undefined)}>
            {activeFieldForTranslations && (
                <PipelineNodeFieldTranslation
                    pipelineNodeId={props.node.id as string}
                    fieldId={activeFieldForTranslations.id}
                />
            )}
            
        </Modal>
        <PipelineNodeColumnDrawer
            pipelineNodeId={activeColumnPipelineNodeId}
            column={activeColumn}
            onHide={() => {
                setActiveColumnPipelineNodeId('');
                setActiveColumn(undefined);
            }}
            show={!!activeColumn && !!activeColumnPipelineNodeId}
        />
        <PaneContent>
        <>
            {currentlyDragging && (
                <>
                    <ScrollCatcher className="top" onDragOver={() => scrollUp()}/>
                    <ScrollCatcher className="bottom" onDragOver={() => scrollDown()}/>

                </>
            )}
            <Modal show={showAddSourceModal} onHide={() => setShowAddSourceModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Connect Source</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Select new source node</Form.Label>
                        <PipelineNodeSelector
                            onSelect={(pn: PipelineNode|undefined) => {

                                setNewSourceSelection(pn ? pn.id as string : '');
                            }}
                            selectedId={newSourceSelection}
                            optionFilter={newSourceOptionFilter}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <button disabled={!newSourceSelection} className="btn btn-pliable" onClick={onConnectSource}>Add Source</button>
                </Modal.Footer>
            </Modal>
                                
                <div className="d-flex" style={{height: '100%'}}>
                    <DndProvider backend={HTML5Backend}>
                        <div style={{width: '65%'}}>
                                
                                    
                            <Pane>
                                <PaneContent>
                                    <div className="p-3">
                                        <PipelineNodeFieldMappingDataSourceList
                                            disableAddingMultipleSources={!!props.limitToSingleSource}
                                            targetNode={props.node}
                                            dataSourceSelectionMode={props.includeRelationships ? 'ALL_RELATED' : 'ONLY_UPSTREAM_NODES'}
                                            onChangeUpstreamNodeIds={updateUpstreamNodeIds}
                                            onDrop={onDrop}
                                            onAddNewSource={() => setShowAddSourceModal(true)}
                                            onUpsert={onUpsertColumns}
                                            onShowColumnStats={onShowColumnStats}
                                        
                                        />
                                        <hr />
                                        <PipelineNodeWhitelistConfiguration node={props.node} onChange={props.onChange}/>
                                    </div>
                                </PaneContent>
                            </Pane>

                                            
                                        
                        </div>
                        <div style={{width: '35%'}}>
                            <Pane>
                                    <PaneContent ref={scrollingContainer}>
                                        <div className="p-3">
                                            <HeaderContainer>
                                                <h4>This Node's Columns</h4>
                                                {inDraftMode && (
                                                    <>
                                                        <button onClick={() => sortFieldsAlphabetically()} title="Sort fields alphabetically" style={{marginRight: '7px'}}>
                                                            <i className="mdi mdi-sort-ascending"></i>
                                                        </button>
                                                        
                                                        <button onClick={() => addNewFieldAtTop()} title="Add new Column">
                                                            <i className="mdi mdi-plus-thick"></i>
                                                        </button>
                                                    </>
                                                )}
                                                
                                            </HeaderContainer>
                                            <SubHeaderContainer>
                                                
                                            <input type="text" className="form-control round-input" placeholder="Search this node's columns" value={boSearchInput} onChange={(e) => setBoSearchInput(e.target.value)}/>
                                                
                                                
                                            </SubHeaderContainer>
                                            {props.enableMerge && (
                                                <>
                                                    {mergeText && <div>
                                                        <div className="d-flex center-vertically">
                                                            <h4 className="mb-0 flex-1">
                                                                {props.mergeTextMode == 'GROUP_BY' && <>
                                                                    Group By Columns
                                                                </>}
                                                                {props.mergeTextMode == 'MERGE' && <>
                                                                    Merge Columns
                                                                </>}
                                                                {props.mergeTextMode == 'IDENTIFY' && <>
                                                                    Identification Columns
                                                                </>}
                                                            </h4>
                                                            {mergingFields.length >= 2 && !props.useGroupByInsteadOfMerge && (
                                                                <div>
                                                                    <Form.Check
                                                                        disabled={!inDraftMode}
                                                                        label="Match all"
                                                                        checked={props.node.combine_logic_gate === 'AND'}
                                                                        onChange={(e) => props.onChange(draft => {
                                                                            draft.combine_logic_gate = e.target.checked ? 'AND' : 'OR';
                                                                        })}
                                                                    />
                                                                </div>
                                                            )}
                                                            
                                                        </div>
                                                        
                                                        <MergeInfo>
                                                            {props.mergeTextMode == 'GROUP_BY' && <>
                                                                Output will be grouped by <strong>{mergeText}</strong>
                                                            </>}
                                                            {props.mergeTextMode == 'MERGE' && <>
                                                                Records with the same values for <strong>{mergeText}</strong> will be merged.

                                                            </>}
                                                            {props.mergeTextMode == 'IDENTIFY' && <>
                                                                Records with the same values for <strong>{mergeText}</strong> will be assigned the same identity.

                                                            </>}
                                                        </MergeInfo>
                                                        
                                                    </div>}
                                                    {!mergeText && !!props.requireMerge && <div>
                                                        <Warning>
                                                            <div>You must select at least 1 column to 
                                                                {props.mergeTextMode == 'GROUP_BY' && <> group by</>}
                                                                {props.mergeTextMode == 'MERGE' && <> merge on</>}
                                                                {props.mergeTextMode == 'IDENTIFY' && <> identify records</>}
                                                            &nbsp;(<span className="text-pliable"><i className="mdi mdi-key"></i></span>)</div>
                                                        </Warning>    
                                                    </div>}
                                                    
                                                    
                                                    
                                                    {mergingFields.map(field => {
                                                        let error: string = '';
                                                        if (!field.map_options || (field.map_options.length != props.node.upstream_node_ids.length && props.node.combine_logic_gate == 'AND')) {
                                                            error = 'You need to map one field from each source in order to use this field for merging.';
                                                        }
                                                        return <>
                                                            <FieldListItem
                                                                disabled={!inDraftMode}
                                                                key={field.id}
                                                                field={field}
                                                                toggleFieldCompositeKey={toggleFieldCompositeKey}
                                                                onDeleteField={onDeleteField}
                                                                onSelectField={setActiveFieldId}
                                                                isActive={activeFieldId == field.id}
                                                                enableMerge={props.enableMerge}
                                                                
                                                            />
                                                            {error && <Danger>
                                                                {error}
                                                            </Danger>}
                                                        </>
                                                    })}
                                                </>
                                            )}
                                            
                                            {props.enableMerge && <>
                                                <hr />
                                                <h4>Other Columns</h4>
                                            </>}
                                            {inDraftMode && <AddFieldButton onAddNewField={addNewFieldAtTop} addTo='TOP'/>}
                                            
                                            {nonMergingFields.map(field => <>
                                                <FieldListItem
                                                    key={field.id}
                                                    disabled={!inDraftMode}
                                                    field={field}
                                                    toggleFieldCompositeKey={toggleFieldCompositeKey}
                                                    onDeleteField={onDeleteField}
                                                    onSelectField={setActiveFieldId}
                                                    isActive={activeFieldId == field.id}
                                                    enableMerge={props.enableMerge}
                                                />
                                            </>)}

                                            {nonMergingFields.length > 0 && inDraftMode && (
                                                <AddFieldButton onAddNewField={addNewFieldAtBottom} addTo='BOTTOM'/>                                                        

                                            )}
                                            
                                        </div>
                                    </PaneContent>
                                </Pane>
                        </div>
                        
                    </DndProvider>
                </div>
        </>
        </PaneContent>
        
        
        
    </Pane>
    
}


export default PipelineNodeFieldsMapping;