import React from 'react';
import { PipelineNode, PipelineNodeField } from '@models/pipelineNode';

interface UnlockedNodeRequiredProps {
    pipelineNode: PipelineNode;
    children: React.ReactNode;
    hide?: boolean;
}

export const UnlockedNodeRequired: React.FC<UnlockedNodeRequiredProps> = ({ pipelineNode, children, hide }) => {
    
    const unlocked = pipelineNode.unlocked;

    return (
        <div className={!unlocked && pipelineNode.managed_by_template_id ? 'disabled' : ''} style={{ display: hide ? 'none' : 'block' }}>
            {children}
        </div>
    );
};


interface UnlockedFieldRequiredProps {
    pipelineNodeFields: PipelineNodeField[];
    children: React.ReactNode;
}

export const UnlockedFieldRequired: React.FC<UnlockedFieldRequiredProps> = ({ pipelineNodeFields, children }) => {
    return (
        <>
            {pipelineNodeFields.map((pipelineNodeField, index) => {
                const unlocked = pipelineNodeField.unlocked;
                const managedByTemplate = pipelineNodeField.managed_by_template_id;
                
                return (
                    <div key={index} className={!unlocked && managedByTemplate ? 'disabled' : ''}>
                        {children}
                    </div>
                );
            })}
        </>
    );
};



